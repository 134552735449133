import React from "react"
import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

/**
 *
 */
class MyStorePage extends React.Component {
    /**
     *
     * @returns {XML}
     */
    render() {
        return (
            <Layout>
                <SEO title="My Local Store"/>

                <div className="container mt-3">
                    <h1 className="text-center">My Local Store</h1>

                    <div className="text-center mt-5">
                        <Link className="btn btn-secondary" to="/">Back Home</Link>
                    </div>
                </div>
            </Layout>
        )
    }
}

export default MyStorePage
